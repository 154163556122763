import { BarChartOutlined } from '@ant-design/icons'
import { Tabs, TabsProps, Typography } from 'antd'
import React, { PropsWithChildren } from 'react'
import StickyBox from 'react-sticky-box'
import background from '../../assets/img/5594016.jpg'
import Assessment from './components/assesment.component'
import Mentoring from '../../pages/Mentoring/components/mentoring.component'
import './soluctions.component.scoped.scss'
const { Title, Paragraph } = Typography

const Soluctions = ({ children }: PropsWithChildren) => {
  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <StickyBox offsetTop={0} style={{ zIndex: 1, height: '39px' }}>
      <DefaultTabBar {...props} />
    </StickyBox>
  )
  const soluctions = [
    {
      label: <span className={'title-solution'}>MENTORIA DE LIDERANÇA</span>,
      key: '1',
      children: <Mentoring></Mentoring>,
    },
    // {
    //   label: <span className={'title-solution'}>Análise de Perfil Comportamental</span>,
    //   key: '2',
    //   children: (
    //     <div style={{ textAlign: 'center' }}>
    //       <AuditOutlined style={{ fontSize: '30px' }} />
    //       <br />
    //       <Title className={'title-solution'} level={2} style={{ color: '#FFF', textAlign: 'center' }}>
    //         Análise de Perfil Comportamental
    //       </Title>
    //       <span>
    //         O Assessment PDA (Personal Development Analysis) é uma ferramenta que permite analisar o perfil comportamental das pessoas,
    //         através de uma metodologia simples, precisa e cientificamente validada. O PDA fornece informações poderosas que podem ser
    //         utilizadas nos processos de coaching, recrutamento e seleção, gestão de carreira, desenvolvimento de lideranças e gestão de
    //         equipes.
    //       </span>
    //     </div>
    //   ),
    // },

    {
      label: <span className={'title-solution'}>ASSESSMENT PDA®</span>,
      key: '3',
      children: <Assessment></Assessment>,
    },
    // {
    //   label: <span className={'title-solution'}>Treinamento</span>,
    //   key: '4',
    //   children: (
    //     <div style={{ textAlign: 'center' }}>
    //       <ApartmentOutlined style={{ fontSize: '30px' }} />
    //       <br />
    //       <Title className={'title-solution'} level={2} style={{ color: '#FFF', textAlign: 'center' }}>
    //         Treinamento e Workshop
    //       </Title>
    //       <span>
    //         Com experiência na condução de times e seus desafios, oferecemos um serviço personalizado para o desenvolvimento de equipes.
    //         Através das estratégias e realidade da organização, montamos um treinamento para desenvolver e transformar sua equipe. Entre em
    //         contato conosco para obter mais informações.
    //       </span>
    //     </div>
    //   ),
    // },
    {
      label: <span className={'title-solution'}>Gestão de Carreira</span>,
      key: '5',
      children: (
        <div style={{ textAlign: 'center' }}>
          <BarChartOutlined style={{ fontSize: '30px' }} />
          <br />
          <Title className={'title-solution'} level={2} style={{ color: '#FFF', textAlign: 'center' }}>
            Gestão de Carreira
          </Title>
          <span>
            Coaching é um processo estruturado, focado no futuro e alicerçado ao planejamento, identificação de competências, implementação
            de ações definidas, por meio de utilização de técnicas e ferramentas cientificamente comprovadas, visa elevar o desempenho do
            indivíduo e estabelecer objetivos para o seu desenvolvimento.
          </span>
        </div>
      ),
    },
  ]

  return (
    <section
      id={'section-soluctions'}
      style={{
        backgroundImage: `url(${background})`,
        minHeight: '400px',
        padding: '200px 50px',
      }}
    >
      <Title className={'title-section'} level={1} style={{ color: '#37c8b7', textAlign: 'center' }}>
        Nossas Soluções
      </Title>
      <div className="line"></div>
      <Tabs
        tabPosition={'top'}
        renderTabBar={renderTabBar}
        tabBarStyle={{ color: '#37c8b7', borderColor: '#37c8b7' }}
        animated={true}
        type={'card'}
        items={soluctions}
      />
    </section>
  )
}

export default Soluctions
