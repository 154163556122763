import {
  faChartArea,
  faChildReaching,
  faCircleNodes,
  faHandshake,
  faHeadSideVirus,
  faScaleBalanced,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Typography } from 'antd'
import React from 'react'
import './service.component.scss'
const { Title, Paragraph } = Typography
export const page1 = {
  title: 'teste',
  children: [
    {
      key: 'inovation-1',
      title: 'Inovação Pulsante',
      icon: <FontAwesomeIcon icon={faHeadSideVirus} size="3x" />,
      src: 'https://gw.alipayobjects.com/zos/rmsportal/KtRzkMmxBuWCVjPbBgRY.svg',
      color: '#582e77',
      shadowColor: 'rgba(166, 55, 112, 0.08)',
      description:
        'No cerne de nossa organização, a inovação é uma força contínua e vital, impulsionando constantemente o futuro por meio de ideias transformacionais e soluções criativas.',
    },
    {
      key: 'connection-2',
      title: 'Conexões que Inspiram',
      icon: <FontAwesomeIcon icon={faCircleNodes} size="3x" />,
      src: 'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
      color: '#582e77',
      shadowColor: 'rgba(112, 73, 166, 0.08)',
      description:
        'Arquitetamos conexões humanas significativas, onde a empatia e o respeito florescem, impulsionando o potencial de todos os envolvidos.',
    },
  ],
  children2: [
    {
      key: 'energy-3',
      title: 'Energia Contagiante',
      icon: <FontAwesomeIcon icon={faChildReaching} size="3x" />,
      src: 'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
      color: '#582e77',
      shadowColor: 'rgba(112, 73, 166, 0.08)',
      description:
        'Com uma energia contagiante, irradiamos paixão e entusiasmo, impulsionando a jornada rumo ao sucesso e à realização de sonhos.',
    },
    {
      key: 'compromisso-3',
      title: 'Compromisso Inabalável',
      icon: <FontAwesomeIcon icon={faHandshake} size="3x" />,
      src: 'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
      color: '#582e77',
      shadowColor: 'rgba(112, 73, 166, 0.08)',
      description:
        'Nosso compromisso é inabalável, sempre determinados a superar obstáculos e garantir a excelência em tudo o que fazemos.',
    },
  ],
  children3: [
    {
      key: 'etic-3',
      title: 'Ética Baseada em Princípios',
      icon: <FontAwesomeIcon icon={faScaleBalanced} size="3x" />,
      src: 'https://gw.alipayobjects.com/zos/rmsportal/qIcZMXoztWjrnxzCNTHv.svg',
      color: '#582e77',
      shadowColor: 'rgba(15, 93, 166, 0.08)',
      description:
        'Nossas raízes são fortemente entrelaçadas com princípios éticos, guiando nossas ações com integridade e transparência, sempre buscando o melhor para todos.',
    },
    {
      key: 'results-4',
      title: 'Resultados consistentes',
      icon: <FontAwesomeIcon icon={faChartArea} size="3x" />,
      src: 'https://gw.alipayobjects.com/zos/rmsportal/eLtHtrKjXfabZfRchvVT.svg',
      color: '#582e77',
      shadowColor: 'rgba(112, 73, 166, 0.08)',
      description:
        'Nossa busca incessante por resultados sustentáveis nos leva a ter uma base sólida, alcançando o sucesso de forma responsável e duradoura.',
    },
  ],
}

const Values = () => {
  return (
    <section className="sec-values">
      <div className="page-wrapper page1">
        <div className="page">
          {/* <Title className={'title-section'} level={2} style={{ color: '#fff', textAlign: 'center' }}>
            Nossos Valores
          </Title>
          <div className="line"></div> */}
          {/* <p style={{ color: '#fff', textAlign: 'center', fontSize: '20px' }}>
            Na base do nosso trabalho, trilhamos um caminho único e criativo, pautado por valores inabaláveis:
          </p> */}
          <Flex gap="middle" align="start" justify="center">
            {page1.children.map((item) => (
              <div key={item.key} className="page1-item-img" style={{ boxShadow: `0 16px 32px ${item.shadowColor}` }}>
                {/* <img width={'50px'} src={item.src} alt="img" /> */}
                <p className="text-center">{item.icon}</p>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))}
          </Flex>
          <Flex gap="middle" align="start" justify="center" style={{ marginTop: '10px' }}>
            {page1.children2.map((item) => (
              <div key={item.key} className="page1-item-img" style={{ boxShadow: `0 16px 32px ${item.shadowColor}` }}>
                {/* <img width={'50px'} src={item.src} alt="img" /> */}
                <p>{item.icon}</p>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))}
          </Flex>
          <Flex gap="middle" align="start" justify="center" style={{ marginTop: '10px' }}>
            {page1.children3.map((item) => (
              <div key={item.key} className="page1-item-img" style={{ boxShadow: `0 16px 32px ${item.shadowColor}` }}>
                {/* <img width={'50px'} src={item.src} alt="img" /> */}
                <p>{item.icon}</p>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))}
          </Flex>
        </div>
      </div>
    </section>
  )
}
export default Values
