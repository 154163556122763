import React from 'react'
import './assessments.page.scss'

export interface AssessmentsProps {
  prop?: string
}

const AssessmentsPage = ({ prop = 'default value' }: AssessmentsProps) => {
  return <div className={'assessments'}>assessments {prop}</div>
}
export default AssessmentsPage
