import '@fontsource/montserrat' // Defaults to weight 400
import '@fontsource/montserrat/400-italic.css' // Specify weight and style
import '@fontsource/montserrat/400.css' // Specify weight
import '@fontsource/poppins'
import '@fontsource/poppins/400-italic.css' // Specify weight and style
import '@fontsource/poppins/400.css' // Specify weight
import { ConfigProvider, Layout, ThemeConfig } from 'antd'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes, createBrowserRouter } from 'react-router-dom'
import Soluctions from './components/soluctions/soluctions.component'
import './fonts/DancingScript-Regular.ttf'
import './fonts/Roboto-Light.ttf'
import './index.scss'
import DefaultLayout from './layouts/default.layout'
import AboutPage from './pages/About/About.page'
import App from './pages/App'
import reportWebVitals from './reportWebVitals'
import MentoringPage from './pages/Mentoring/Mentoring.page'
import AssessmentsPage from './pages/assessments/assessments.page'
import web from '../src/assets/img/WEB SITE.png'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
])

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#582e77',
    colorInfo: '#582e77',
    //colorBgContainer: '#582e77',
    wireframe: false,
  },
  components: {
    Menu: {
      colorItemBg: 'transparent',
      colorItemTextSelected: '#FFFFFF',
      colorSubItemBg: '#582e776b',
      colorPrimary: '#9d7eb8',
      colorBgContainer: 'rgba(88, 46, 119, 55)',
      colorPrimaryBg: 'rgba(88, 46, 119, 55)',
      colorBgElevated: 'rgba(88, 46, 119, 55)',
      colorItemBgActive: '#9d7eb8',
      colorItemBgSelected: '#9d7eb8',
      colorItemText: '#FFFFFF',
      colorItemTextHover: '#FFFFFF',
      colorItemBgSelectedHorizontal: 'transparent',
      colorItemTextSelectedHorizontal: '#FFFFFF',
      colorItemBgHover: 'rgba(157, 127, 183, 0.46)',
      colorPrimaryBgHover: 'rgba(157, 127, 183, 0.46)',
    },
  },
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter basename="/">
    {' '}
    <Routes>
      <Route
        element={
          <ConfigProvider theme={theme}>
            {/* <DefaultLayout /> */}
            <Layout>
              <div>
                <img src={web} alt="" style={{ width: '100%' }} />
              </div>
            </Layout>
          </ConfigProvider>
        }
      >
        <Route path="/" element={<div></div>}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/quem-somos" element={<AboutPage />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/mentoria-lideranca" element={<MentoringPage />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/assessment-pda" element={<AssessmentsPage />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/seguranca-psicologica" element={<Soluctions />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/disc-index" element={<Soluctions />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/values-index" element={<Soluctions />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/atribute-index" element={<Soluctions />}></Route> {/* 👈 Renders at /app/ */}
        <Route path="/management-dilemmas" element={<Soluctions />}></Route> {/* 👈 Renders at /app/ */}
      </Route>
    </Routes>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
