/* eslint-disable jsx-a11y/media-has-caption */
import { WhatsAppOutlined } from '@ant-design/icons'
import banner from '@assets/img/backgrounds/banner-home.jpg'
import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import './banner.component.scss'

export interface BannerProps {
  backgroundImage?: string
  title?: string
  buttonCall?: boolean
  buttonText?: string
  buttonSoluctions?: boolean
}

const Banner = ({ backgroundImage = banner, title, buttonCall, buttonSoluctions, buttonText }: BannerProps) => {
  return (
    <section className="banner" id="banner" style={{ padding: '0 50px', backgroundImage: `url(${backgroundImage})`, textAlign: 'center' }}>
      <div>
        <p className="vazada">{title}</p>
      </div>
      <br />
      <div className="act">
        {buttonCall ? (
          <Button
            shape="round"
            icon={<WhatsAppOutlined />}
            size={'large'}
            type="primary"
            href="https://wa.me/5542998326676/?text=Olá! Gostaria de um orçamento."
            target="blank"
          >
            Fale com a gente
          </Button>
        ) : null}
        {buttonSoluctions ? (
          <Button shape="round" onClick={() => {}} size={'large'} type="primary">
            <Link to={'/solutions'}>Nossas Soluções</Link>
          </Button>
        ) : null}
      </div>
    </section>
  )
}

export default Banner
