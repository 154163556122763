import { Card, Layout, Typography } from 'antd'
import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import backgroundImage from '../../assets/svg/background-color.svg'

const { Header, Footer, Sider, Content } = Layout

const { Title, Paragraph } = Typography
const depoimentos = [
  {
    name: 'Juliano',
    descricao:
      'Boa tarde Lidiane, Primeiramente gostaria de agradecer a empresa e a você em poder participar da mentoria Neste período em que tivemos os encontros foram muito importantes, nos dá suporte encorajamento e aprendizado, em se desenvolver, desenvolver novas habilidades, e nos ajuda a ser um melhor profissional a cada dia. Nessa caminhada profissional sem dúvida, ter uma mentoria, faz a diferença em se atualizar, refletir e conquistar mais espaço dentro da organização.',
  },
  {
    name: 'Juliano',
    descricao:
      'Boa tarde Lidiane, Primeiramente gostaria de agradecer a empresa e a você em poder participar da mentoria Neste período em que tivemos os encontros foram muito importantes, nos dá suporte encorajamento e aprendizado, em se desenvolver, desenvolver novas habilidades, e nos ajuda a ser um melhor profissional a cada dia. Nessa caminhada profissional sem dúvida, ter uma mentoria, faz a diferença em se atualizar, refletir e conquistar mais espaço dentro da organização.',
  },
]

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
const Depositions = () => (
  <section style={{ backgroundImage: `url(${backgroundImage})`, padding: '120px 50px ', minHeight: '400px' }}>
    <Title level={2} className={'title-section color-white'}>
      Quem acredita no nosso trabalho
    </Title>
    <div className="line"></div>
    <Content style={{ fontFamily: 'DancingScript-Regular' }}>
      <Carousel responsive={responsive} autoPlay>
        {depoimentos.map((client, index) => (
          <Card hoverable key={client.name} bordered={false} style={{ width: '200px' }}>
            {client.name}
            {client.descricao}
          </Card>
        ))}
      </Carousel>
    </Content>
  </section>
)

export default Depositions
