import background from '@assets/img/backgrounds/webp/lilac-arrow.webp'
import { Typography } from 'antd'
import React from 'react'

import './about.component.scoped.scss'

const { Text, Title, Paragraph } = Typography

export const page4 = {
  name: 'Lidiane Drum',
  profile: '',
  founder: '',
  comment: 'Ant Design, a design language for background applications, is refined by Ant UED Team. Ant Design, a design language for',
}

const About = () => {
  return (
    <section className="sec-about" style={{ backgroundImage: `url(${background})`, textAlign: 'left' }}>
      <div style={{ marginTop: '80px', padding: '0 50px' }}>
        <Title className={'title-section'} style={{ color: '#FFF' }} level={1}>
          Quem somos?
        </Title>
        <div className="line"></div>
        <Paragraph className={'texts-white'}>
          Em 2011, nasceu um sonho audacioso e revolucionário de formar líderes inspiracionais e promotores da diferença positiva dentro das
          Organizações. Desde então, nossa incessante busca por excelência se tornou o cerne de nossa trajetória. Hoje, desafiamos o status
          quo e abrimos novos horizontes para o sucesso, impactando positivamente a vida de indivíduos e organizações.
        </Paragraph>
        <div className="text-destak">
          <p>
            Nós somos especialistas em oferecer um desenvolvimento de liderança altamente personalizado, fornecendo soluções digitais e
            programas In Company por meio de mentorias, treinamentos e capacitações de alta qualidade.
          </p>
          <p>
            Nossas soluções incluem ferramentas de renome internacional, tais como o Assessment PDA, Questionário de Resiliência, Dilemas de
            Gestão, Disc Index, Values Index e Atribute Index.
          </p>
        </div>
        <Paragraph className={'texts-white'} style={{ textAlign: 'center' }} italic>
          Convidamos você a se juntar a nós nessa cativante jornada, onde a liderança inspiradora é a força motriz que nos impulsiona rumo
          ao futuro!
        </Paragraph>
      </div>
    </section>
  )
}

export default About
