import { Card, Flex, Typography } from 'antd'
import React from 'react'

import './video-institucional.component.scoped.scss'
// import lidi from '../../../assets/img/lidiane.jpg'
const { Text, Title, Paragraph } = Typography

export const page4 = {
  name: 'Lidiane Drum',
  profile: '',
  founder: '',
  comment: 'Ant Design, a design language for background applications, is refined by Ant UED Team. Ant Design, a design language for',
}

const VideoInstitucional = () => {
  return (
    <section className="video-inst" style={{ textAlign: 'left' }}>
      <iframe
        width="900"
        height="515"
        src="https://www.youtube.com/embed/K_bgrS5NR6M?si=yuIacRdyc1KlgAIA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      {/*  */}
    </section>
  )
}

export default VideoInstitucional
