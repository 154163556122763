import {
  faBrain,
  faBullseye,
  faFileLines,
  faGears,
  faHandHoldingDollar,
  faHandHoldingHeart,
  faMagnifyingGlass,
  faSquarePollVertical,
  faTimeline,
  faUsersGear,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Card, Col, Row, Timeline, Typography } from 'antd'
import React, { PropsWithChildren } from 'react'
const { Title, Paragraph } = Typography

const MentoringComponent = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <Title className={'title-solution'} level={1} style={{ color: '#37c8b7', textAlign: 'center' }}>
            Mentoria de Liderança
          </Title>
        </Col>
        <Col span={12} style={{ padding: '20px', margin: '20px', backgroundColor: '#FFF', borderRadius: '8px' }}>
          <Timeline
            items={[
              {
                dot: <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: '25px' }} />,
                color: '#37c8b7',
                children: (
                  <p style={{ padding: '0 20px' }}>
                    <h2>Diagnóstico</h2>
                    <p>Análise de cenário;</p>
                    <p>Identificação dos marcos da jornada;</p>
                    <p>Verificação com Stakeholders;</p>
                  </p>
                ),
              },
              {
                dot: <FontAwesomeIcon icon={faUsersGear} style={{ fontSize: '25px' }} />,
                color: '#37c8b7',
                children: (
                  <p style={{ padding: '0 20px' }}>
                    <h2>Desenvolver</h2>
                    <p>Análise do perfil comportamental;</p>
                    <p>Construção da linha de base;</p>
                    <p>Desenvolver habilidades;</p>
                    <p>Desdobramento de indicadores;</p>
                    <p>Feedback 360.</p>
                  </p>
                ),
              },
              {
                dot: <FontAwesomeIcon icon={faSquarePollVertical} style={{ fontSize: '25px' }} />,
                color: '#37c8b7',
                children: (
                  <p style={{ padding: '0 20px' }}>
                    <h2>Gestão de Resultados</h2>
                    <p>Consolidação do PDI;</p>
                  </p>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Row justify="center" align="top">
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faBrain} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Diagnóstico comportamental através de ferramentas cientificamente comprovadas internacionais.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faTimeline} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Estruturação dos marcos que compõem a trajetória profissional do mentorado.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faBullseye} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Construção da linha de base: com indentificação das virtudes, ganhos e perdas, valores e propósito.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faGears} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Protagonismo e gestão da carreira.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faHandHoldingHeart} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Segurança psicológica, resiliência e o profissional antifrágil.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faUsersGear} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Técnicas de manejo e intervenção comportamental.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faFileLines} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Planejamento organizacional de tarefas, voltado para produtividade e gestão do tempo.</span>
          </Card>
        </Col>
        <Col span={6} xs={24} sm={12} md={6} style={{ margin: '10px', textAlign: 'center' }}>
          <Card style={{ height: '160px' }}>
            <FontAwesomeIcon icon={faHandHoldingDollar} style={{ fontSize: '30px', marginBottom: '10px', color: '#37c8b7' }} />
            <br />
            <span>Formar novos líderes, com táticas de remuneração e benefícios alhinhadas à estratégia da organização.</span>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MentoringComponent
