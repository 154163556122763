import fusopar from '@src/assets/img/clientes/fusopar.png'
import interpump from '@src/assets/img/clientes/interpump.webp'
import ir9 from '@src/assets/img/clientes/ir9.webp'
import random from '@src/assets/img/clientes/randon.png'
import sucessor from '@src/assets/img/clientes/suc-rh.webp'
import tca from '@src/assets/img/clientes/tca.png'
import tecnomaq from '@src/assets/img/clientes/tecnomaq.png'
import tecnovidro from '@src/assets/img/clientes/tecnovidro.svg'
import telasul from '@src/assets/img/clientes/telasul.png'
import { Card, Image, Typography } from 'antd'
import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const { Title, Paragraph } = Typography

const clients = [
  {
    name: 'Tecnomaq',
    key: 'tecnomaq',
    logo: tecnomaq,
    link: 'https://www.tecnomaq.com.br/',
  },
  {
    name: 'Tecnovidro',
    key: 'tecnovidro',
    logo: tecnovidro,
    link: 'https://www.tecnovidro.com.br/',
  },
  {
    name: 'Sucessor RH',
    key: 'Sucessor-rh',
    logo: sucessor,
    link: 'https://www.sucessor-rh.com.br/',
  },
  {
    name: 'TCA Digital',
    key: 'TCA-Digital',
    logo: tca,
    link: 'https://tca.digital/',
  },
  {
    name: 'IR9',
    key: 'IR9',
    logo: ir9,
    link: 'https://www.sucessor-rh.com.br/',
  },
  {
    name: 'Randon Implementos',
    key: 'Randon-Implementos',
    logo: random,
    link: 'https://www.randonimplementos.com.br/pt',
  },
  {
    name: 'Qualita',
    key: 'Qualita',
    logo: sucessor,
    link: 'https://www.qualitatautopecas.com.br/loja/',
  },
  {
    name: 'Telasul',
    key: 'Telasul',
    logo: telasul,
    link: 'https://www.fusopar.com.br/',
  },
  {
    name: 'Fusopar',
    key: 'Fusopar',
    logo: fusopar,
    link: 'https://www.fusopar.com.br/',
  },
  {
    name: 'Interpump',
    key: 'Interpump',
    logo: interpump,
    link: 'https://www.fusopar.com.br/',
  },
]
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
const Clients: React.FC = () => (
  <section style={{ margin: '40px', textAlign: 'center', justifyContent: 'center' }}>
    <Title className={'title-section'} level={2}>
      Empresas que confiam em nós
    </Title>
    <div className="line"></div>
    <Carousel responsive={responsive} infinite={true} autoPlay>
      {clients.map((client, index) => (
        <Card hoverable key={client.key} bordered={false} style={{ width: '200px', backgroundColor: '#e3e3e3' }}>
          <a href={client.link} title={client.name}>
            <Image src={client.logo} width="100px" preview={false} />
          </a>
        </Card>
      ))}
    </Carousel>
  </section>
)

export default Clients
