import { Content } from 'antd/es/layout/layout'
import React from 'react'
import Banner from '../components/banner/banner.component'
import Service from '../components/service/service.component'
import Videos from '../components/videos/videos.component'
import './App.css'
import Clients from '../components/clients/clients.component'
import Depositions from '../components/depositions/depositions.component'

function App() {
  return (
    <>
      <Banner></Banner>
      <Content className="site-layout">
        <Service></Service>
        <Videos></Videos>
        <Depositions></Depositions>
        <Clients></Clients>
        {/* <Soluctions></Soluctions> */}
      </Content>
    </>
  )
}

export default App
