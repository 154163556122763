import { Card, Col, List, Row, Statistic, Timeline, Typography } from 'antd'
import React, { PropsWithChildren } from 'react'
const { Title, Paragraph } = Typography

const Assessment = ({ children }: PropsWithChildren) => {
  const data = [
    'Reconhecer as aptidões e capacidades profissionais para ajustar o plano de carreira;',
    'Desenvolver líderes e equipes nos aspectos comportamentais e organizacionais;',
    'Identificar o perfil profissional que melhor atenda a necessidade da empresa no processo de recrutamento e seleção;',
    'Embasar o mapeamento de perfis de lideranças para a sucessão corporativa;',
    'Desenvolvimento pessoal & coaching.',
  ]
  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <Card>
            <Title className={'title-solution'} level={1} style={{ color: '#37c8b7', textAlign: 'center' }}>
              ASSESSMENT PDA®
              <br />
              (Personal Development Analysis)
            </Title>

            <p style={{ textAlign: 'center' }}>
              Tecnologia internacional, capaz de identificar o perfil comportamental do indivíduo, descrevendo habilidades, pontos fortes,
              áreas a desenvolver, estilo de tomada de decisão e de liderança, dentre outras informações significativas ao processo da
              gestão de pessoas e de autoconhecimento. Com o PDA® é possível identificar o perfil do indivíduo e da equipe.
            </p>
            <List
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  {index + 1} - {item}
                </List.Item>
              )}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#37c8b7',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Statistic title=" " value={90} valueStyle={{ color: '#582e77' }} suffix="%" />
              </div>
              <span
                style={{
                  borderTop: '2px solid #37c8b7',
                  borderBottom: '2px solid #37c8b7',
                  borderRight: '2px solid #37c8b7',
                  color: '#37c8b7',
                  padding: '20px',
                }}
              >
                Fidedignidade
              </span>
            </div>
            {/* Box 2 */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{
                  borderTop: '2px solid #37c8b7',
                  borderBottom: '2px solid #37c8b7',
                  borderLeft: '2px solid #37c8b7',
                  color: '#37c8b7',
                  padding: '20px',
                }}
              >
                Melhora na efetividade dos processos seletivos
              </span>
              <div
                style={{
                  backgroundColor: '#37c8b7',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Statistic title=" " value={75} valueStyle={{ color: '#582e77' }} suffix="%" />
              </div>
            </div>
            {/* Box 3 */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#37c8b7',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Statistic title=" " value={50} valueStyle={{ color: '#582e77' }} suffix="%" />
              </div>
              <span
                style={{
                  borderTop: '2px solid #37c8b7',
                  borderBottom: '2px solid #37c8b7',
                  borderRight: '2px solid #37c8b7',
                  color: '#37c8b7',
                  padding: '20px',
                }}
              >
                Redução de turnover
              </span>
            </div>
            <p>
              A ferramenta PDA extrai até <b>7 tipos de relatório</b> para cada Análise de Perfil. Pode ser usada para diversos objetivos e
              rotinas do RH como:
            </p>
            <Timeline
              items={[
                {
                  children: (
                    <>
                      <h2>Recrutamento e Seleção</h2>
                      <p>Promoção interna e sucessão.</p>
                    </>
                  ),
                },
                {
                  children: (
                    <>
                      <h2>Mapear e alocar talentos</h2>
                      <p>Desenvolver líderes e equipes.</p>
                    </>
                  ),
                },
                {
                  children: (
                    <>
                      <h2>Gestão comportamental</h2>
                      <p>Treinamento e desenvolvimento.</p>
                    </>
                  ),
                },
                {
                  children: (
                    <>
                      <h2>Plano de desenvolvimento individual</h2>
                      <p>Assessment</p>
                      <p>Coach</p>
                    </>
                  ),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Assessment
