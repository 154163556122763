import React from 'react'

import { Card, Flex, Typography } from 'antd'
import Banner from '../../components/banner/banner.component'
import './Mentoring.page.scss'
import MentoringComponent from './components/mentoring.component'
const { Text, Title, Paragraph } = Typography

export interface MentoringProps {
  prop?: string
}

const MentoringPage = ({ prop = 'default value' }: MentoringProps) => {
  return (
    <div className="mentoring">
      <Banner title="Desbloqueie seu Potencial de Liderança com a Mentoria da Natus"></Banner>
      <section className="sec1">
        <Paragraph style={{ fontSize: '1.25rem', textAlign: 'left' }}>
          Você está pronto para destravar, potencializar e acelerar sua trajetória como líder?
        </Paragraph>
        <Paragraph style={{ fontSize: '1.25rem', textAlign: 'left' }}>
          Se a resposta é sim, a Mentoria de Liderança da Natus é a chave que você precisa para mudar o jogo e alcançar todo o seu potencial
          como líder.
        </Paragraph>
        <Paragraph style={{ fontSize: '1.25rem', textAlign: 'left' }}>
          Muitas vezes, a diferença entre um líder eficaz e um líder excepcional está na consciência dos próprios comportamentos e na
          aplicação das estratégias essenciais que definem um líder de impacto. É hora de você assumir o controle de sua liderança,
          transformar sua performance e multiplicar seus resultados, enquanto mantém sua saúde mental em dia.
        </Paragraph>
        <Paragraph style={{ fontSize: '1.25rem', textAlign: 'left' }}>
          Acredite, líder, os obstáculos que estão impedindo você de atingir seu máximo desempenho não são externos. Eles estão dentro de
          você, e nós estamos aqui para ajudá-los a superá-los.
        </Paragraph>
      </section>
      <section className="sec2">
        <Paragraph style={{ fontSize: '1.25rem', textAlign: 'left', color: '#fff' }}>
          Você está pronto para destravar, potencializar e acelerar sua trajetória como líder?
        </Paragraph>
        <Flex gap="middle" align="start" justify="center">
          <Card title="Líderes em Potencial" bordered={false} className="card-mentoring">
            Se você está em busca de desenvolvimento profissional e deseja acelerar sua jornada rumo à liderança, esta mentoria é para você.
          </Card>
          <Card title="Líderes Ascensão" bordered={false} className="card-mentoring">
            Mesmo líderes experientes podem se beneficiar da orientação personalizada que oferecemos. Aprenda a enfrentar desafios
            específicos, aprimore suas habilidades de liderança e continue crescendo.
          </Card>
          <Card title="Empresários e Empreendedores" bordered={false} className="card-mentoring">
            Se você está liderando seu próprio negócio, a Mentoria de Liderança da Natus pode ajudá-lo a aprimorar suas habilidades de
            liderança, tomar decisões estratégicas e alcançar o sucesso sustentável.
          </Card>
        </Flex>
        {/* <MentoringComponent></MentoringComponent> */}
      </section>
    </div>
  )
}

export default MentoringPage
