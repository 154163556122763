import { Content } from 'antd/es/layout/layout'
import React from 'react'
import './About.page.scss'
import AboutFounder from './components/about-founder/about-founder.component'
import About from './components/about/about.component'
import VideoInstitucional from './components/video-institucional/video-institucional.component'

const AboutPage = () => {
  return (
    <Content className="about">
      <About></About>
      <AboutFounder></AboutFounder>
      <VideoInstitucional></VideoInstitucional>
    </Content>
  )
}

export default AboutPage
